@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";
body {
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 24px;
  overflow-x: hidden;
  letter-spacing: .4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-container{
  width: 80%;
  margin: auto;
}
