.htmlTable table {
    margin: 10px 0;
}


.htmlTable table tr td:nth-child(1) {
    background-color: #f3f3f3;
    border-bottom: 3px solid #fff;
    color: #666666;
    width: 35%;
    padding: 0 10px;
}

.htmlTable table tr td:nth-child(2) {
    padding: 10px 15px;
    border-bottom: 1px #eaeaea solid;
    word-break: break-word;
    white-space: pre-wrap;
}

.htmlTable table tr td:nth-child(1) {
    margin:10px 0 !important;
}


.html_description{
    width: 100% !important;
}

.html_description table{
    width: 100% !important;
}