.home_page_background {
  background-image: url('../assets/Img/hero.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  /* background-size: cover; */
}

@supports (-webkit-touch-callout: none) {
  .home_page_background {
    background: url('../assets/Img/hero.jpg') center top no-repeat scroll;
    background-size: auto 130vh;
  }
}

.review_bg {
  background: linear-gradient(90deg, #edac15 50%, #212121 50%);
}

@media only screen and (max-width: 1024px) {
  .review_bg {
    background: #212121;
  }
}

.carousel.carousel-slider .control-arrow {
  top: 50%;
  height: min-content;
  opacity: 0.9;
  font-size: 40px;
}

.carousel.carousel-slider .control-arrow:hover {
  top: 50%;
  height: min-content;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.522);
  font-size: 40px;
}

.carousel .control-next.control-arrow:before {
  border-left: 15px solid #fff;
}

.carousel .control-prev.control-arrow:before {
  border-right: 15px solid #fff;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
