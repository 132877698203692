.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*-------------inventory-details page Slider Button Css ---------------  */
.image-gallery-right-nav{
  background: rgb(0, 0, 0,.5);
  height: auto;
  padding: 30px 5px !important;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.image-gallery-right-nav .image-gallery-svg{
  height: 25px !important;

}
.image-gallery-right-nav .image-gallery-svg{
  width: 25px !important;
}

.image-gallery-left-nav{
  background: rgb(0, 0, 0,.5);
  height: auto;
  padding: 30px 5px !important;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.image-gallery-left-nav .image-gallery-svg{
  height: 25px !important;

}
.image-gallery-left-nav .image-gallery-svg{
  width: 25px !important;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #204682;
  /* Light grey */
  border-top: 10px solid #fff;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}